<template>
    <!-- <keep-alive> -->
    <router-view :key="$route.fullPath" class="animated fadeIn" />
    <!-- </keep-alive> -->
</template>

<script>
/* eslint-disable no-unused-vars */
import { onMounted } from "vue";

export default {
    name: "RouterViewComponent",
    props: {
        moduleName: { type: String, default: null },
    },
    emits: [ "set-module-name" ],
    setup(props, { emit }) {
        onMounted(() => {
            emit("set-module-name", props.moduleName);
        });
    },
};
</script>
